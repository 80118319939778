"use client";

import React from "react";
import classNames from "classnames";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {NewsletterModal} from "@shared/types/payload-types";
import {TrackingData} from "@shared/types/editorial-types";

import {toast, wordings} from "@business/components/common/notification/Message";
import {tracker} from "@business/components/common/Tracker";
import {NewsletterFormSchema, newsletterFormSchema} from "./formsSchemas";
import {submitHubSpotForm} from "./helpers/submitHubSpotForm";
import Form from "../Form";
import TextField from "../text-field/TextField";

type NewsletterFormProps = {
    className?: string;
    coupon?: string | null;
    formTracker: string;
    inFooter?: boolean;
    notifications?: NewsletterModal["notifications"];
    onSubmit?: () => void;
    source: "modal" | "footer" | "block";
    submitButtonFullWidth?: boolean;
    submitButtonJustify?: "center" | "left";
    submitButtonText: string;
    trackingData?: TrackingData;
};

const formReference = "newsletter";

export default function NewsletterForm({
    className,
    coupon,
    formTracker,
    inFooter,
    notifications,
    onSubmit,
    source,
    submitButtonFullWidth,
    submitButtonText,
    submitButtonJustify,
    trackingData,
}: NewsletterFormProps) {
    const pageUri = typeof window !== "undefined" ? location.href : null;

    const {
        register,
        reset,
        handleSubmit,
        formState: {errors, isSubmitting},
    } = useForm<NewsletterFormSchema>({
        resolver: zodResolver(newsletterFormSchema),
    });

    const errorNotification = wordings.issue_retry;
    const successNotification = notifications?.success
        ? {
              title: notifications.success.title,
              message: notifications.success.description,
              success: true,
          }
        : wordings.newsletter_form_submit_success;

    const onSubmitWrapper = async (data: NewsletterFormSchema) => {
        try {
            const body = JSON.stringify({...data, pageUri});
            await submitHubSpotForm({body, formReference});
            tracker.submitForm({formReference, container: trackingData?.container});
            toast(successNotification);
            reset();
            onSubmit && onSubmit();
        } catch (error) {
            toast(errorNotification);
            tracker.error(error as Error);
        }
    };

    const trackingFields = (
        <>
            <input type="hidden" value={coupon ? coupon : undefined} {...register("coupon")} />
            <input type="hidden" value={formReference} {...register("formReference")} />
            <input type="hidden" value={formTracker} {...register("formTracker")} />
            <input type="hidden" value={`newsletter_${source}`} {...register("newsletterSource")} />
        </>
    );

    if (inFooter === true) {
        return (
            <form
                className={classNames("ng-footer-newsletter-form", className)}
                onSubmit={handleSubmit(onSubmitWrapper)}
            >
                <input className="ng-field-email" type="text" placeholder="Votre email" {...register("email")} />
                {trackingFields}
                <button className="ng-button-submit" type="submit">
                    {submitButtonText}
                </button>
            </form>
        );
    }

    return (
        <Form
            className={className}
            isSubmitting={isSubmitting}
            onSubmit={handleSubmit(onSubmitWrapper)}
            submitButtonFullWidth={submitButtonFullWidth}
            submitButtonJustify={submitButtonJustify}
            submitButtonText={submitButtonText}
        >
            <TextField
                disabled={isSubmitting}
                error={errors.email?.message}
                label="Email"
                name="email"
                placeholder="ange.porteux@gmail.com"
                register={register}
            />
            {trackingFields}
        </Form>
    );
}
