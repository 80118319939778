import React, {useCallback, useMemo, useState} from "react";
import classes from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";
import {faWarning as errorIcon, faEye as showIcon, faEyeSlash as hideIcon} from "@fortawesome/free-solid-svg-icons";

import FieldWrapper from "../field-wrapper/FieldWrapper";
import Suite from "../../primitives/Suite";
import {InputAdornment} from "../input-adornment/InputAdornment";

type TextFieldProps = {
    "adornmentLeft"?: string | IconDefinition;
    "adornmentRight"?: string | IconDefinition;
    "data-cy"?: string;
    "description"?: string;
    "disabled"?: boolean;
    "error"?: string;
    "id"?: string;
    "label"?: string;
    "name": string;
    "onChange"?: (event: any) => void;
    "placeholder"?: string;
    "register"?: any;
    "required"?: boolean;
    "type"?: "text" | "hidden" | "password";
    "value"?: string;
};

export default function TextField({
    adornmentLeft,
    adornmentRight,
    "data-cy": dataCy,
    description,
    disabled,
    error,
    id = "text-field",
    label,
    name,
    onChange,
    placeholder,
    type = "text",
    value,
    register,
    required = true,
}: TextFieldProps) {
    const [inputType, setInputType] = useState(type);

    const toggleVisibilityIcon = useMemo(() => (inputType === "password" ? showIcon : hideIcon), [inputType]);
    const toggleVisibility = useCallback(
        () => setInputType(inputType === "password" ? "text" : "password"),
        [inputType],
    );

    return (
        <FieldWrapper error={error} description={description} id={id} label={label} required={required}>
            <Suite
                className={classes("ng-text-field", {"ng-field-error": error, "ng-field-disabled": disabled})}
                data-cy={dataCy}
                spacing="small"
                wrap="never"
            >
                {adornmentLeft && <InputAdornment adornment={adornmentLeft} />}
                <input
                    disabled={disabled}
                    id={id}
                    name={name}
                    onChange={onChange}
                    placeholder={placeholder}
                    type={inputType}
                    value={value}
                    {...register(name, {required})}
                />
                {error && <FontAwesomeIcon icon={errorIcon} className="ng-text-field-error-icon" />}
                {adornmentRight && <InputAdornment adornment={adornmentRight} />}
                {type === "password" && <InputAdornment adornment={toggleVisibilityIcon} onClick={toggleVisibility} />}
            </Suite>
        </FieldWrapper>
    );
}
